<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-alert :show="errors.length > 0" variant="danger">
      <h4 class="alert-heading">
        Виникла помилка при збереженні нового контрагента!
      </h4>
      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
    </b-alert>
    <form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        label="Основні дані"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <!-- Тип контрагента -->
        <ValidationProvider
          name="тип контрагента"
          rules="required"
          v-slot="{ errors, dirty }"
        >
          <b-form-group label="Тип контрагента">
            <b-form-select
              @change="onChangeTypeFreshForm"
              text-field="name"
              value-field="value"
              v-model="form.type"
              :options="types"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Тип контрагента -->

        <!-- Профіль СПФМ -->
        <ValidationProvider
          name="профіль СПФМ"
          rules="required"
          v-slot="{ errors, dirty, validated, valid }"
          vid="financial_model_id"
        >
          <b-form-group label="Профіль СПФМ">
            <b-form-select
              v-model="form.financial_model_id"
              :options="meFinancialModels"
              text-field="name"
              value-field="id"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Профіль СПФМ -->

        <!--  Прізвище контрагента  -->
        <ValidationProvider
          v-if="isIndividual(type) || isNonResidentIndividual(type)"
          name="Прізвище"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
            required: true,
          }"
          v-slot="{ errors, dirty }"
        >
          <b-form-group label="Прізвище">
            <b-form-input
              type="text"
              v-model="form.last_name"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Прізвище контрагента -->

        <!--  Ім'я контрагента  -->
        <ValidationProvider
          v-if="isIndividual(type) || isNonResidentIndividual(type)"
          name="ім'я"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
            required: true,
          }"
          v-slot="{ errors, dirty }"
        >
          <b-form-group label="Ім'я">
            <b-form-input
              type="text"
              v-model="form.first_name"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Ім'я контрагента -->

        <!--  По батькові контрагента  -->
        <ValidationProvider
          v-if="isIndividual(type) || isNonResidentIndividual(type)"
          name="по батькові"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
            required: !isNonResidentIndividual(type),
          }"
          v-slot="{ errors, dirty }"
        >
          <b-form-group label="По батькові">
            <b-form-input
              type="text"
              v-model="form.middle_name"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: По батькові контрагента -->

        <!--  Повне найменування контрагента  -->
        <ValidationProvider
          v-if="isNonResidentLegal(type)"
          name="повне найменування контрагента"
          :rules="{
            required: true,
            regex: $stringConstants('REGEX_TEXT_NAME'),
          }"
          v-slot="{ errors, dirty }"
        >
          <b-form-group label="Повне найменування контрагента">
            <b-form-input
              type="text"
              v-model="form.name"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Повне найменування контрагента -->

        <!-- Код нерезидента -->
        <ValidationProvider
          v-if="isNonResident(type)"
          v-slot="{ errors, dirty }"
          name="код нерезидента"
          :rules="
            isNonResidentIndividual(type) || isNonResidentLegal(type)
              ? { required: true, max: 255 }
              : { max: 255 }
          "
        >
          <b-form-group label="Код нерезидента">
            <b-form-input
              type="text"
              maxlength="255"
              v-model="form.non_resident_code"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Код нерезидента -->

        <!-- Країна резидент -->
        <ValidationProvider
          v-if="isNonResident(type)"
          v-slot="{ errors, dirty, validated, valid, changed }"
          name="країна резидент"
          rules="required"
          vid="country_id"
        >
          <b-form-group label="Країна резидент">
            <b-form-select
              v-model="form.country_id"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              :options="countryList"
              text-field="name.short-name"
              value-field="id"
            >
            </b-form-select>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Країна резидент -->

        <!-- ЄДРПОУ -->
        <ValidationProvider
          v-slot="{ errors, dirty }"
          v-if="isLegal(type)"
          name="ЄДРПОУ"
          :rules="{ required: true, regex: /^[\d]{8}$/ }"
        >
          <b-form-group label="ЄДРПОУ">
            <b-form-input
              type="text"
              placeholder="8-ми значний номер"
              v-model="form.usreou_code"
              maxlength="8"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: ЄДРПОУ -->

        <!-- РНОКПП -->
        <ValidationProvider
          v-if="isIndividualEntrepreneur(type) || isIndividual(type)"
          v-slot="{ errors, dirty }"
          name="РНОКПП"
          :rules="{ required: true, regex: /^[\d]{10}$/ }"
        >
          <b-form-group label="РНОКПП">
            <b-form-input
              type="text"
              placeholder="10-ти значний номер"
              maxlength="10"
              v-model="form.itn_code"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: РНОКПП -->
      </b-form-group>

      <b-form-group
        label="Реквізити банку, в якому відкрито рахунок, і номер поточного рахунка."
        label-size="lg"
        label-class="font-weight-bold"
        v-if="!isNonResident(type)"
      >
        <!-- Номер рахунка -->
        <ValidationProvider
          v-slot="{ errors, dirty }"
          name="номер рахунка"
          :rules="{ regex: /^[A-Z]{2}[\d]{27}$/ }"
        >
          <b-form-group label="Номер рахунка">
            <b-form-input
              type="text"
              placeholder="Номер рахунка в Iban форматі"
              maxlength="29"
              v-model="form.iban"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <!-- END: Номер рахунка -->
      </b-form-group>

      <div slot="footer">
        <b-button type="submit" size="sm" variant="success">
          <i class="fa fa-dot-circle-o"></i> Додати
        </b-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import typeMixin from "../mixins/type";
import mixins from "@/mixins";

export default {
  name: "CounterpartForm",
  mixins: [typeMixin, mixins],
  data() {
    return {
      form: {
        name: "",
        financial_model_id: "",
        last_name: "",
        first_name: "",
        middle_name: "",
        type: "",
        usreou_code: "",
        itn_code: "",
        iban: "",
        non_resident_code: "",
        country_id: "",
      },
      errors: [],
    };
  },
  computed: {
    ...mapGetters({
      types: "dictionary/allCounterpartTypes",
      meFinancialModels: "auth/meFinancialModels",
      countryList: "dictionary/allCountries",
    }),
    type: function () {
      const foundType = this.types.find(
        (item) => item.value === this.form.type
      );
      if (foundType === -1) return null;
      return foundType;
    },
  },
  methods: {
    onChangeTypeFreshForm() {
      Object.keys(this.form).forEach((key) => {
        if (key !== "type" && key !== "iban") {
          this.form[key] = "";
        }
      });
    },
    onSubmit: function () {
      let form = {};
      Object.entries(this.form).forEach(([key, value]) => {
        if (value !== "") form[key] = value;
      });
      this.errors = [];
      this.$store
        .dispatch("counterparts/add", form)
        .then(() => {
          this.$root.$emit("bv::hide::modal", "cPartForm");
          this.$snotify.success("Збережено нового контрагента");
          this.$store.dispatch("contracts/pullCounterparts");
        })
        .catch(({ response }) => {
          if (
            response.status !== 406 ||
            !response.data ||
            !response.data.description
          ) {
            this.$root.$emit("bv::hide::modal", "cPartForm");
            this.$snotify.error(
              "Виникла помилка при збереженні нового контрагента"
            );
            return;
          }

          window.scrollTo(0, 0);
          for (let variable in response.data.description)
            if (response.data.description.hasOwnProperty(variable)) {
              this.errors = this.errors.concat(
                response.data.description[variable]
              );
            }
        });
    },
  },
};
</script>

<template>
  <b-modal
    id="export-counterpart-modal"
    title="Експорт контрагентів"
    hide-footer
    @show="refreshModal()"
    @hidden="refreshModal()"
  >
    <error-alert
      :errors="errors"
      message="Виникла помилка експорта контрагентів!"
    />
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          name="тип експорту контрагентів"
          :rules="{ required: true }"
          v-slot="{ errors, dirty }"
        >
          <b-form-group label="Оберіть експорт який вам потрібен">
            <b-form-select
              text-field="text"
              value-field="route"
              v-model="selectedOptionRoute"
              :options="exportOptions"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <div slot="footer">
          <b-button
            size="sm"
            type="submit"
            variant="success"
            :disabled="submitting"
          >
            Підтвердити
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import ErrorAlert from "@/components/ErrorAlert.vue";
import api from "@/api/api";

export default {
  name: "CounterpartExportModal",
  props: {
    propFilters: {
      type: Object,
      required: true,
    },
  },
  components: { ErrorAlert },
  computed: {
    exportOptions: function () {
      const options = [
        {
          text: "Експорт всіх контрагентів",
          route: "list",
        },
        {
          text: "Форма 4",
          route: "form4",
        },
      ];

      return options;
    },
  },
  data() {
    return {
      request: null,
      selectedOptionRoute: null,
      submitting: false,
      errors: {},
    };
  },
  methods: {
    refreshModal() {
      this.selectedOptionRoute = null;
      this.errors = {};
    },
    replaceLikeWithILike(key) {
      return key.includes("@like") ? key.replace("@like", "@ilike") : key;
    },
    convertKeys(obj) {
      const newObject = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          let [firstPart, secondPart] = key.split("@");
          firstPart = firstPart.replace(
            /([A-Z])/g,
            (letter) => `_${letter.toLowerCase()}`
          );
          let newKey = secondPart ? `${firstPart}@${secondPart}` : firstPart;
          if (newKey === "filter[financial_model@whereIn]") {
            newKey = "filter[financial_model_id@whereIn]";
          }
          newKey = this.replaceLikeWithILike(newKey);
          newObject[newKey] = obj[key];
        }
      }
      return newObject;
    },
    onSubmit() {
      this.submitting = true;
      this.errors = {};
      if (this.selectedOptionRoute === "list") {
        this.request = this.$store.dispatch("counterparts/exportData");
      } else {
        this.request = api.exportCounterparts(
          this.selectedOptionRoute,
          this.convertKeys(this.propFilters)
        );
      }
      this.request
        .then(() => {
          this.$snotify.success("Файл буде надіслано на вашу пошту.");
          this.$bvModal.hide("export-counterpart-modal");
        })
        .catch((error) => {
          this.$snotify.error("Помилка експорта операцій");
          this.errors = {
            code: error.response.status,
          };
        })
        .finally(() => (this.submitting = false));
    },
  },
};
</script>

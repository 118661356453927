import {
  INDIVIDUAL_ENTREPRENEUR_TYPE_VALUE,
  INDIVIDUAL_TYPE_VALUE,
  LEGAL_PERSON_TYPE_VALUE,
  GOVERNMENT_UNIT_TYPE_VALUE,
  SEPARATE_UNIT_TYPE_VALUE,
  INDIVIDUAL_NON_RESIDENT_TYPE_VALUE,
  LEGAL_NON_RESIDENT_TYPE_VALUE
} from "@/constants";

export default {
  methods: {
    isLegal(type) {
      if (this.isTypeValue(type, LEGAL_PERSON_TYPE_VALUE)) return true;
      if (this.isTypeValue(type, GOVERNMENT_UNIT_TYPE_VALUE)) return true;
      return this.isTypeValue(type, SEPARATE_UNIT_TYPE_VALUE);
    },
    isIndividualEntrepreneur(type) {
      return this.isTypeValue(type, INDIVIDUAL_ENTREPRENEUR_TYPE_VALUE);
    },
    isIndividual(type) {
      return this.isTypeValue(type, INDIVIDUAL_TYPE_VALUE);
    },
    isNonResident(type) {
      if (this.isTypeValue(type, INDIVIDUAL_NON_RESIDENT_TYPE_VALUE))
        return true;
      return this.isTypeValue(type, LEGAL_NON_RESIDENT_TYPE_VALUE);
    },
    isNonResidentLegal(type) {
      return this.isTypeValue(type, LEGAL_NON_RESIDENT_TYPE_VALUE);
    },
    isNonResidentIndividual(type) {
      return this.isTypeValue(type, INDIVIDUAL_NON_RESIDENT_TYPE_VALUE);
    },
    isTypeValue(type, typeValue) {
      if (!type) return false;
      const types = this.$store.getters["dictionary/allCounterpartTypes"];
      const foundType = types.find(item => item.id === type.id);
      if (foundType === -1) return false;
      return foundType.value === typeValue;
    }
  }
};

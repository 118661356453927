<template>
  <div>
    <b-card :header="caption">
      <b-row>
        <b-col cols="auto" class="mr-auto p-3">
          <b-button
            v-if="$auth.can('create counterpart')"
            variant="success"
            size="sm"
            @click="newItemModal = true"
            class="mb-2"
          >
            <i class="fa fa-dot-circle-o"></i> Додати
          </b-button>
        </b-col>

        <b-col cols="auto" class="p-3">
          <b-button
            variant="primary"
            size="sm"
            class="mb-2"
            v-b-modal.export-counterpart-modal
            :disabled="hasEmptyDataList"
          >
            <i class="fa fa-dot-circle-o"></i> Експорт
          </b-button>
        </b-col>
      </b-row>

      <filter-form />

      <b-table
        :dark="false"
        :fields="fields"
        :hover="true"
        :striped="true"
        :bordered="true"
        :small="false"
        :fixed="true"
        responsive="sm"
        :items="applyShortFilter(dataList)"
      >
        <template v-slot:cell(name)="data">
          <b-link @click="followToCurrentItem(data.item.id)">
            {{ data.item.name }}
          </b-link>
        </template>

        <template v-slot:cell(created)="data">
          {{ data.item.created | date }}
        </template>

        <template v-slot:cell(identificationDate)="data">
          {{ data.item.identificationDate | date }}
        </template>

        <template v-slot:cell(clarificationDate)="data">
          {{ data.item.clarificationDate | date }}
        </template>

        <template v-slot:cell(currentPublicPerson)="data">
          {{ data.item.currentPublicPerson | boolToText }}
        </template>

        <template v-slot:cell(publicityDate)="data">
          {{ data.item.publicityDate | date }}
        </template>

        <template v-slot:cell(crimeRiskLevel)="data">
          <div
            class="circle-block"
            :style="{
              backgroundColor: setColorCrimeRiskLevel(data.item.crimeRiskLevel),
            }"
          ></div>
          {{ data.item.crimeRiskLevel | setCrimeRiskLevel }}
          {{ data.item.crimeRiskLevelDate | date }}
        </template>

        <template v-slot:cell(contract)="data">
          <router-link
            v-for="item in visibleContracts(data.item)"
            :key="item.id"
            :to="{ path: `${contractEndpoint}/${item.id}` }"
            target="_blank"
          >
            {{ item.number }}
            <br />
          </router-link>
          <b-iconstack
            font-scale="2"
            variant="white"
            v-if="data.item.contract.length > 3"
            @click="toggleShowAllContracts(data.item)"
          >
            <b-icon stacked icon="square-fill" variant="secondary" />
            <b-icon
              variant="dark"
              stacked
              scale="0.65"
              icon="chevron-compact-up"
              :rotate="data.item.showAllContracts ? 0 : 180"
            />
          </b-iconstack>
        </template>

        <template v-slot:cell(actions)="data">
          <b-button
            v-if="!data.item.deleted"
            variant="ghost-info link"
            size="sm"
            @click="followToCurrentItem(data.item.id)"
          >
            <i class="fa fa-edit"></i>
          </b-button>

          <b-button
            v-if="
              !data.item.deleted &&
              $auth.can($stringConstants('PERMISSION_PRINT_COUNTERPART'))
            "
            size="sm"
            variant="ghost-info link"
            @click="printCurrentPdfItem(data.item.id)"
          >
            <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
          </b-button>

          <b-button
            v-if="
              !data.item.deleted &&
              $auth.can($stringConstants('PERMISSION_PRINT_COUNTERPART'))
            "
            size="sm"
            variant="ghost-info link"
            @click="printCurrentDocItem(data.item.id)"
          >
            <i class="fa fa-file-text-o"></i>
          </b-button>

          <router-link
            target="_blank"
            :to="{
              name: 'Monitoring',
              query: {
                processedAtFrom: currentMonthDates[0],
                processedAtTo: currentMonthDates[1],
                financialModelId: data.item.financialModelId,
                counterpartIds: data.item.id,
              },
            }"
          >
            <b-button
              size="sm"
              variant="ghost-info link"
              v-b-tooltip.hover
              title="Моніторинг"
            >
              <i class="fa fa-external-link"></i>
            </b-button>
          </router-link>

          <b-button
            v-if="$auth.can('create counterpart') && !data.item.deleted"
            variant="ghost-danger"
            size="sm"
            @click="deleteItem(data.item.id)"
          >
            <i class="fa fa-close"></i>
          </b-button>

          <b-button
            v-if="$auth.can('create counterpart') && data.item.deleted"
            variant="ghost-danger"
            size="sm"
            @click="restoreItem(data.item.id)"
          >
            <i class="fa fa-undo"></i>
          </b-button>
        </template>
      </b-table>

      <b-row>
        <b-col cols="auto" class="mr-auto p-3">
          <nav>
            <b-pagination
              :total-rows="totalItemsCount"
              :per-page="count"
              v-model="page"
              prev-text="Попередня"
              next-text="Наступна"
              hide-goto-end-buttons
            />
          </nav>
        </b-col>

        <b-col cols="auto" class="p-3">
          <b-form-radio-group
            buttons
            v-model="count"
            :options="options"
            button-variant="outline-primary"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="cPartForm"
      title="Новий контрагент"
      no-close-on-backdrop
      hide-footer
      size="lg"
      v-model="newItemModal"
      @ok="newItemModal = false"
    >
      <counterpart-form />
    </b-modal>

    <b-modal id="modalRemove" @ok="okRemove">
      <p class="my-4">Впевнені ?</p>
    </b-modal>

    <b-modal id="modalRestore" @ok="okRestore">
      <p class="my-4">Відновити ?</p>
    </b-modal>

    <counterpart-export-modal :prop-filters="prepareFilterParams()" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CounterpartForm from "./forms/CounterpartForm";
import debounce from "debounce";
import FilterForm from "./forms/FilterForm";
import { mapFields } from "vuex-map-fields";
import moment from "moment/moment";
import CounterpartExportModal from "@/entity/Counterparts/CounterpartExportModal.vue";
import filterList from "@/entity/Counterparts/structure/filter";
import {
  EXTREMELY_HIGH_VALUE,
  HIGH_VALUE,
  MIDDLE_VALUE,
  LOW_VALUE,
} from "@/constants";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$auth.can("view counterpart")) next();
      else next({ name: "Page404" });
    });
  },
  name: "counterpart-list",
  components: {
    CounterpartExportModal,
    CounterpartForm,
    FilterForm,
  },
  data() {
    return {
      options: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "200", value: 200 },
      ],
      fields: [
        { key: "name", label: "Контрагент" },
        { key: "created", label: "Дата створення", tdClass: "text-center" },
        { key: "type", label: "Тип контрагента" },
        { key: "edrpou", label: "ЄДРПОУ/РНОКПП" },
        { key: "financialModel", label: "Профіль СПФМ" },
        {
          key: "crimeRiskLevel",
          label: "Рівень ризику",
          tdClass: "text-center",
        },
        { key: "identificationDate", label: "Дата ідентифікації" },
        { key: "clarificationDate", label: "Дата уточнення" },
        { key: "identificationStatus", label: "Статус ідентифікації" },
        { key: "currentVerification", label: "Спосіб верифікації" },
        {
          key: "currentPublicPerson",
          label:
            "Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами",
        },
        { key: "publicityDate", label: "Дата останнього оновлення" },
        { key: "site", label: "Веб-сайт" },
        { key: "contract", label: "Договори" },
        { key: "status", label: "Статус ділових відносин" },
        { key: "actions", label: "Дії" },
      ],
      itemEndpoint: "/entity/counterpart",
      contractEndpoint: "/entity/contract",
      caption: "Контрагенти",
      newItemModal: false,
      removeId: null,
      restoreId: null,
      filter: filterList,
      currentMonthDates: [],
    };
  },
  computed: {
    ...mapGetters({
      dataList: "counterparts/getAllCounterparts",
      totalItemsCount: "counterparts/totalItems",
    }),
    ...mapFields("counterparts", {
      count: "count",
      page: "page",
    }),
    hasEmptyDataList() {
      return this.dataList.length === 0;
    },
  },
  watch: {
    page: function () {
      this.debouncedPaginator();
    },
    count: function () {
      this.debouncedPaginator();
    },
  },
  created() {
    this.debouncedPaginator = debounce(this.paginator, 500);
  },
  methods: {
    toggleShowAllContracts(item) {
      if (typeof item.showAllContracts === "undefined") {
        this.$set(item, "showAllContracts", false);
      }
      item.showAllContracts = !item.showAllContracts;
    },
    visibleContracts(item) {
      if (item.showAllContracts) {
        return item.contract;
      } else {
        return item.contract.slice(0, 3);
      }
    },
    prepareFilterParams: function () {
      const filters = {};
      Object.entries(this.filter).forEach(([, filter]) => {
        if (
          filter.value !== null &&
          filter.value !== "" &&
          filter.value.length !== 0
        ) {
          filters[`filter[${filter.name}@${filter.type}]`] = filter.value;
        }
      });
      return {
        page: this.page,
        per_page: this.count,
        ...filters,
      };
    },
    paginator: function () {
      this.$store.dispatch("counterparts/setCounterparts");
    },
    applyShortFilter(data) {
      return data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          created: item.created_at,
          deleted: item.deleted_at,
          type: item.type.name,
          status: item.status ? item.status.name : null,
          edrpou: item.usreou_code
            ? item.usreou_code
            : item.itn_code
            ? item.itn_code
            : item.non_resident_code,
          financialModel: item.financial_model
            ? item.financial_model.name
            : null,
          financialModelId: item.financial_model
            ? item.financial_model.id
            : null,
          site: item.risk_management ? item.risk_management.site : null,
          identificationDate: item.profile_maintain
            ? item.profile_maintain.identification_date
            : null,
          clarificationDate: item.profile_maintain
            ? item.profile_maintain.clarification_date
            : null,
          identificationStatus:
            item.profile_maintain && item.profile_maintain.identification_status
              ? item.profile_maintain.identification_status.name
              : null,
          currentPublicPerson: item.clarification
            ? item.clarification.is_public
            : null,
          currentVerification:
            item.profile_maintain && item.profile_maintain.verification
              ? item.profile_maintain.verification.name
              : null,
          publicityDate: item.clarification
            ? item.clarification.publicity_date
            : null,
          crimeRiskLevelDate:
            item.risk_management &&
            item.risk_management.crime_risks &&
            item.risk_management.crime_risks.length !== 0
              ? item.risk_management.crime_risks[
                  item.risk_management.crime_risks.length - 1
                ].date
              : null,
          crimeRiskLevel:
            item.risk_management && item.risk_management.crime_risks
              ? item.risk_management.crime_risks
              : null,
          contract: item.contracts ? item.contracts : null,
          _rowVariant: item.deleted_at ? "danger" : null,
        };
      });
    },
    followToCurrentItem(itemId) {
      this.$router.push(this.itemEndpoint + "/" + itemId);
    },
    okRemove() {
      this.$store.dispatch("counterparts/deleteItem", this.removeId);
    },
    deleteItem(itemId) {
      this.removeId = itemId;
      this.$root.$emit("bv::show::modal", "modalRemove");
    },
    okRestore() {
      this.$store.dispatch("counterparts/restoreItem", this.restoreId);
    },
    restoreItem(itemId) {
      this.restoreId = itemId;
      this.$root.$emit("bv::show::modal", "modalRestore");
    },
    printCurrentPdfItem(itemId) {
      this.$store.dispatch("counterparts/print", [itemId, "pdf"]);
    },
    printCurrentDocItem(itemId) {
      this.$store.dispatch("counterparts/print", [itemId, "docx"]);
    },
    setColorCrimeRiskLevel(crimeRiskLevels) {
      if (crimeRiskLevels === null || crimeRiskLevels.length === 0) {
        return "";
      }

      const levelValue =
        crimeRiskLevels[crimeRiskLevels.length - 1].risk_level.value;

      if (levelValue === EXTREMELY_HIGH_VALUE) {
        return "#f86c6b";
      } else if (levelValue === HIGH_VALUE) {
        return "#ffc107";
      } else if (levelValue === MIDDLE_VALUE) {
        return "#4dbd74";
      } else if (levelValue === LOW_VALUE) {
        return "#9ea3a6";
      } else {
        return "";
      }
    },
    getCurrentMonthDates() {
      const today = moment().toDate();
      const startOfMonth = moment().startOf("month").toDate();

      this.currentMonthDates = [startOfMonth, today];
    },
  },
  filters: {
    setCrimeRiskLevel: function (crimeRiskLevels) {
      if (crimeRiskLevels === null || crimeRiskLevels.length === 0) {
        return "";
      }

      return crimeRiskLevels[crimeRiskLevels.length - 1].risk_level.name;
    },
  },
  mounted() {
    this.getCurrentMonthDates();
  },
};
</script>

<style lang="scss" scoped>
.circle-block {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 auto;
}
</style>
